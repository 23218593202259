@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

:root {
  --body-bg-color: #ebfeff;
  --font-color: #333;
  --scrollbar-track-color: #f1f1f1;
  --scrollbar-thumb-color: #888;
  --scrollbar-thumb-hover-color: #555;
  --link-color: #007bff;
}

[data-theme="dark"] {
  --body-bg-color: #333;
  --font-color: #ffffffe5;
  --scrollbar-track-color: #555;
  --scrollbar-thumb-color: #888;
  --scrollbar-thumb-hover-color: #f1f1f1;
  --link-color: #d2baff;
}

body {
  font-family: monospace;
  background-color: var(--body-bg-color);
  color: var(--font-color);
  box-shadow: 0px 0px 2px 1px rgb(131, 130, 130) inset;

  a {
    color: var(--link-color);

  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover-color);
}